<template>
  <app-container
    icon="account_circle"
    colWidth="col-11 col-lg-8"
    v-if="!isTextLoading"
    v-loading="isLoading"
  >
    <template #header>
      <h1 class="mb-4 text-dark">{{ texts.title }}</h1>
    </template>
    <template #body>
      <div class="login">
        <p class="text-start text-dark">
          {{ texts.intro }}
        </p>
        <app-form
          :form="form"
          :model="model"
          :rules="rules"
          :button="texts.submit_button"
          @submitted="handleSubmit"
          ><template #text>
            <div class="mb-4 text-start reset-password-link">
              <router-link :to="{ name: 'Password' }" class="text-dark mt-3">{{
                texts.reset_password
              }}</router-link>
            </div>
          </template>
          <template #button>
            <button
              class="btn-outlined"
              @click="$router.push({ name: 'Register' })"
            >
              <router-link :to="{ name: 'Register' }">
                {{ texts.register_button }}</router-link
              >
            </button>
          </template>
        </app-form>
      </div>
    </template>
  </app-container>
</template>

<script>
import { form, model, rules } from '@/config/forms/auth/login';
import Utilities from '@/utils/services/Utilities';
import useTexts from '@/mixins/useTexts';

export default {
  name: 'LoginPage',
  mixins: [useTexts],
  data() {
    return {
      page: 'login',
      form: [],
      model: {},
      rules: {},
      isLoading: false
    };
  },
  created() {
    this.isLoading = true
    this.form = JSON.parse(JSON.stringify(form));
    this.model = JSON.parse(JSON.stringify(model));
    this.rules = JSON.parse(JSON.stringify(rules));
    this.isLoading = false
  },
  methods: {
    async handleSubmit(data) {
      const status = await this.$store.dispatch('login', {
        email: data.email,
        password: data.password,
      });

      if (status !== 200) return Utilities.showMessage('error', 'login_error');

      this.$router.push({ name: 'Home' });
    },
  },
};
</script>

<style lang="scss">
.login {
  max-width: 25rem;
  margin: 0 auto;
  .reset-password-link {
    a {
      text-decoration: underline !important;
    }
  }
}
</style>
